import { createMuiTheme, Theme } from '@material-ui/core';

// const fonts = ['Be Vietnam', 'sans-serif'].join(',');
const fonts = ['CamphorW01-Regular', 'sans-serif'].join(',');
// const fonts = ['Copyright Klim Type Foundry', 'sans-serif'].join(',');

const colors = {
  primary: '#2446BF',
};

const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
      light: '#B1C0F2',
    },
  },
  typography: {
    fontFamily: fonts,
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
      marginBottom: '1.6rem',
    },
    h3: {
      fontWeight: 600,
      marginBottom: '1.6rem',
    },
    h4: {
      fontWeight: 500,
    },
    body1: {
      fontSize: '1.8rem',
      marginBottom: '1.2rem',
    },
    caption: {
      fontSize: '1.4rem',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        minHeight: '4.4rem',
        '& .MuiButton-label': {
          fontSize: '1.6rem',
          fontWeight: 600,
          textTransform: 'none',
        },
      },
    },
    MuiLink: {
      root: {
        fontFamily: fonts,
        color: colors.primary,
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#FFFFFF',
      },
    },
    MuiInputBase: {
      root: {
        marginBottom: 0,
      },
    },
  },
  //  palette: {
  //    type: 'dark',
  //  },
});

// Responsive font sizes
theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '3.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '2.8rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.2rem',
  },
};

export default theme;
